import axios from 'axios';
import get from 'lodash/get';
import errorLogger from 'shared/utils/errorLogger';
import { normalize } from 'normalizr';
import bonusesSchema from 'shared/schemas/bonuses';
import { asyncNames, createActions } from '../utils';
import notify from '../notify';

export const names = asyncNames('FETCH_BONUSES');
export const bonuses = createActions(names);

const fetchBonuses = (params = {}, axiosOpts) => async dispatch => {
  dispatch(bonuses.request({ params }));
  try {
    const response = await axios(
      AppRouting.generate('api_get_bonuses', params),
      axiosOpts,
    );
    const data = normalize(response.data, bonusesSchema);
    await dispatch(bonuses.success({ params, data }));
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      dispatch(bonuses.cancel({ params, error }));
      return undefined;
    }
    errorLogger(error);
    const message = get(
      error,
      'response.data.errors.global',
      i18next.t('ui|||error|||default'),
    );

    dispatch(notify({ message }));
    dispatch(bonuses.failure({ params, error }));
    throw new Error(message);
  }
};

export default fetchBonuses;
